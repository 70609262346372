import React, { useState } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import { navigate } from "gatsby"
import toast from 'react-hot-toast';


const Form = () => {
  const [data, setData] = useState({
    lastName: '',
    firstName: '',
    phone: '',
    email: '',
  });
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formErrors, setFormErrors] = useState(false)
  const [formSuccess, setFormSuccess] = useState(false)

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const { firstName, lastName, email, phone } = data;
    const errors = []
    if (!firstName) {
      errors.push({
        type: "validation",
        field: "firstName",
        message: "First name is required",
      })
    }
    if (!lastName) {
      errors.push({
        type: "validation",
        field: "lastName",
        message: "Last name is required",
      })
    }
    if (!email) {
      errors.push({
        type: "validation",
        field: "email",
        message: "Email is required",
      })
    }
    if (!phone) {
      errors.push({
        type: "validation",
        field: "phone",
        message: "Phone is required",
      })
    }

    if (errors.length) {
      toast.error(`${errors.map(error => error.message).join('\n ')}`,
        {
          icon: '⚠️',
          style: {
            background: '#333',
            color: '#fff',
            fontSize: '1.2rem',
          },
        }
      );
      console.log(errors);
      return
    }

    // if (window.grecaptcha) {
    //   console.log(grecaptcha.getResponse())
    // }

    setFormSubmitting(true)
    const formData = new FormData()
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('email', email)
    formData.append('phone', phone)

    fetch('/api/v1/leads', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data)
        setFormSubmitting(false)
        setFormSubmitted(true)
        setFormSuccess(true)

        // if (response && response.success) {
        //   setData({
        //     lastName: '',
        //     firstName: '',
        //     phone: '',
        //     email: '',
        //   });
        //   navigate("/thank-you");
        // }
      })
      .catch((error) => {
        console.error('Error:', error)
        setFormSubmitting(false)
        setFormErrors(true)
        setFormSuccess(true)
      })
  }

  return (
    <Grid container spacing={3} style={{ minHeight: 500 }}>
      <Grid
        item={true}
        xs={12}
        sm={12}
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <form onSubmit={handleSubmit}
          data-netlify-recaptcha="true"
          data-netlify="true"
          name="addLeadForm">
          <input type="hidden" name="form-name" value="addLeadForm" />
          <TextField
            label="Nume"
            name="lastName"
            value={data.lastName}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Prenume"
            name="firstName"
            value={data.firstName}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Telefon"
            name="phone"
            value={data.phone}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            value={data.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            type="email"
          />
          <div data-netlify-recaptcha="true"></div>
          <Button type="submit" variant="contained" color="primary">
            Iau creditul
          </Button>
        </form>
      </Grid>
    </Grid>
  )
}
export default Form
